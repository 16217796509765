import { MissionCode, MissionTaskStatus } from 'service/user';
import useStore from 'store/timeline';
import { useMemo } from 'react';
import IGBanner from 'components/atoms/IGBanner';
import InvastBanner from 'components/atoms/InvastBanner';
import { BannerCode } from 'common/utils/banner';
import { isIzanaviMember, isMembershipAccount } from 'common/utils/membership';
import IzanaviBanner from 'components/molecules/IzanaviBanner';
import AnimeAlarmBanner from 'components/common/AnimeAlarmBanner';
import CoinBanner from 'components/common/CoinBanner';

const RandomBanner = ({
  fallbackElement,
  className,
  codes,
  isSupportRandom = true,
  fallbackCodes,
}: {
  fallbackElement?: JSX.Element;
  className?: string;
  codes: string[];
  isSupportRandom?: boolean;
  fallbackCodes: string[];
}) => {
  const allMissions = useStore((state) => state.allMissions);
  const currentUser = useStore((state) => state.currentUser);
  const isMembershipUser = isMembershipAccount(currentUser);
  const bannerCodes = useMemo(() => {
    const fbCodes = (fallbackCodes || [])
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    if (!isSupportRandom) return codes.concat(fbCodes);
    return codes
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
      .concat(fbCodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showingBannerCode = useMemo(() => {
    for (let i = 0; i < bannerCodes.length; i++) {
      const code = bannerCodes[i];
      if (Object.values(MissionCode).includes(code as MissionCode)) {
        const m = allMissions.find((mission) => mission.code === code);
        const ableToShow = [
          MissionTaskStatus.NotYet,
          MissionTaskStatus.InProgress,
        ].includes(m?.current_task?.status);
        if (ableToShow) return m.code;
      }
      if (Object.values(BannerCode).includes(code as BannerCode)) {
        if (code === BannerCode.Izanavi && isIzanaviMember(currentUser)) {
          continue;
        }
        return code;
      }
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allMissions,
    bannerCodes,
    currentUser?.expand_membership_statuses,
    currentUser?.applicable_promotions,
    isMembershipUser,
  ]);

  if (!currentUser) return <></>;
  if (!allMissions || allMissions.length === 0) return <></>;
  if (!showingBannerCode && fallbackElement) return fallbackElement;
  if (showingBannerCode === BannerCode.Izanavi) {
    return <IzanaviBanner className={className} />;
  }
  if (showingBannerCode === BannerCode.CoinInvite) {
    return (
      <div className={className}>
        <CoinBanner />
      </div>
    );
  }

  if (showingBannerCode === BannerCode.Anime) {
    return (
      <div className={className}>
        <AnimeAlarmBanner />
      </div>
    );
  }

  if (showingBannerCode === MissionCode.IGCreateAccount) {
    return <IGBanner fallbackElement={fallbackElement} className={className} />;
  }
  if (showingBannerCode === MissionCode.InvastCreateAccount) {
    return (
      <InvastBanner fallbackElement={fallbackElement} className={className} />
    );
  }
  return <></>;
};

export default RandomBanner;
