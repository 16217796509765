import { Badge, User } from 'common/interfaces/api';
import { AuthError } from 'firebase/auth';
import dayjs from 'lib/dayjs';
import { WebPricePackage, WebPricePackageRecurring } from 'service/payment';
import { DISCOUNT_75_PRIME_SUB_EVENT } from './index.constant';
import {
  addEvent,
  EventAction,
  EventCategory,
  EventComponent,
  ScreenName,
} from './pp_tracking';

export const isAbleToUpgradePlan = (creator: User) => {
  return (
    creator.is_enabled_long_subscription_setting &&
    creator.is_long_subscription_available &&
    creator.creator_subscription &&
    creator.creator_subscription.current_plan &&
    ['active', 'trialing'].includes(creator.creator_subscription.status) &&
    packageLevel(creator.creator_subscription.current_plan) <
      packageLevel(WebPricePackageRecurring.Year) &&
    !['android_iap', 'ios_iap'].includes(creator.creator_subscription?.platform)
  );
};

const packageLevel = (packageName: WebPricePackageRecurring) => {
  switch (packageName) {
    case WebPricePackageRecurring.Month:
      return 1;
    case WebPricePackageRecurring.HalfYear:
      return 2;
    case WebPricePackageRecurring.Year:
      return 3;
    default:
      return 0;
  }
};

export const isChoosingPayAblePackage = (
  currentSubbingPackageName: WebPricePackageRecurring,
  packageName: WebPricePackageRecurring
) => {
  return packageLevel(currentSubbingPackageName) < packageLevel(packageName);
};

export const showingPackages = (
  subPack: WebPricePackageRecurring | null,
  packages: WebPricePackage[],
  currentPlatform: string
) => {
  const isSubbingFromApp = ['android_iap', 'ios_iap'].includes(currentPlatform);
  const subPackLevel = packageLevel(subPack);
  const filterCondition = (p: WebPricePackage) => {
    if (isSubbingFromApp && subPackLevel > 0) {
      return packageLevel(p.recurring) === subPackLevel;
    }
    return packageLevel(p.recurring) >= subPackLevel;
  };
  return packages
    .filter((p) => filterCondition(p))
    .sort((a, b) => packageLevel(a.recurring) - packageLevel(b.recurring));
};

export enum NewOnboardingScreenName {
  AnimeCharacterDescription = 'anime_character_description',
  ChartDescription = 'chart_description',
  LiveDescription = 'live_description',
  ChooseChannels = 'choose_channels',
  MissionsList = 'missions_list',
}

export const showDateTo = (date: string, dateFormat: string) => {
  if (!date) return '現在';
  return dayjs(date).format(dateFormat);
};

export const formatDatoToSend = (date?: Date) => {
  if (!date) return dayjs().format('YYYY-MM-DD');
  return dayjs(date).format('YYYY-MM-DD');
};

export const viewModalInputProfileEvent = (type: 'first_time' | 'remind') => {
  addEvent({
    action: EventAction.PageView,
    category: EventCategory.Agent,
    screen: ScreenName.HomeTimeline,
    component:
      type === 'first_time'
        ? EventComponent.AgentFunctionPopupIntro
        : EventComponent.AgentFunctionPopupRemind,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        label: 'type',
        value: type,
      },
    ],
  });
};

export const clickAgentPopupEvent = (
  position: 'go_to_profile_btn' | 'close_btn',
  component: EventComponent
) => {
  addEvent({
    action: EventAction.Click,
    category: EventCategory.Agent,
    screen: ScreenName.HomeTimeline,
    component: component,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        label: 'click_position',
        value: position,
      },
    ],
  });
};

export const openLoginDialogEvent = (path: string) => {
  addEvent({
    action: EventAction.PageView,
    category: EventCategory.Account,
    screen: ScreenName.HomeTimeline,
    component: EventComponent.LoginDialog,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        label: 'path',
        value: path,
      },
    ],
  });
};

export const createAccountEvent = (path: string) => {
  addEvent({
    action: EventAction.Click,
    category: EventCategory.Account,
    screen: ScreenName.HomeTimeline,
    component: EventComponent.LoginDialog,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        label: 'path',
        value: path,
      },
    ],
  });
};

export const clickPolicyPopup = (position: 'agree_btn' | 'disagree_btn') => {
  addEvent({
    action: EventAction.Click,
    category: EventCategory.Agent,
    screen: ScreenName.RecruitmentProfile,
    component: EventComponent.AgentFunctionPolicyPopup,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        label: 'click_position',
        value: position,
      },
    ],
  });
};

export const viewPolicyPopup = () => {
  addEvent({
    action: EventAction.PageView,
    category: EventCategory.Agent,
    screen: ScreenName.RecruitmentProfile,
    component: EventComponent.AgentFunctionPolicyPopup,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        label: 'type',
        value: 'policy_popup',
      },
    ],
  });
};

export const addPaymentEvent = ({
  component,
  position,
}: {
  component: EventComponent;
  position:
    | 'tipping_with_new_card'
    | 'prime_sub_with_new_card'
    | 'tipping'
    | 'prime_sub'
    | 'add_new_card'
    | 'buy_course_with_new_card'
    | 'buy_course'
    | 'cancel_prime_sub';
}) => {
  addEvent({
    action: EventAction.Click,
    category: EventCategory.Account,
    screen: ScreenName.HomeTimeline,
    component: component,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        value: position,
        label: 'click_position',
      },
    ],
  });
};

export const providerInfo = (provider: string) => {
  switch (provider) {
    case 'password':
      return { name: 'email', width: 24, height: 24, label: 'メールアドレス' };
    case 'google.com':
      return { name: 'google', width: 36, height: 36, label: 'Google' };
    case 'facebook.com':
      return {
        name: 'facebook-blue',
        width: 14,
        height: 26,
        label: 'Facebook',
      };
    case 'twitter.com':
      return { name: 'twitter-blue', width: 21, height: 17, label: 'Twitter' };
    case 'apple.com':
      return { name: 'apple-black', width: 20, height: 24, label: 'Apple' };
    default:
      return null;
  }
};

export const errorCodeToText = (error: AuthError) => {
  switch (error.code) {
    case 'auth/credential-already-in-use':
      return 'このアカウントはすでに使用されています。';
    case 'auth/account-exists-with-different-credential':
      return '既に他のソーシャルアカウントで該当メールアドレスを利用してログインしました。';
    default:
      return 'エラーが発生しました。';
  }
};

export const addChangeProviderEvent = ({
  action,
  value,
  label,
}: {
  action: EventAction;
  value: string;
  label: 'current_provider' | 'linked_provider';
}) => {
  addEvent({
    action: action,
    category: EventCategory.Account,
    screen: ScreenName.AccountSetting,
    component: EventComponent.ChangeProvider,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        value: value,
        label: label,
      },
    ],
  });
};

export const addIgMissionPageEvent = (
  position: 'login_button' | 'mission_button' | 'mission_check_button'
) => {
  addEvent({
    action: EventAction.Click,
    category: EventCategory.Tutorial,
    screen: ScreenName.IGMissionLP,
    component: EventComponent.IGMissionLP,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        value: position,
        label: 'click_position',
      },
    ],
  });
};

export const addIgMissionBannerEvent = (position: 'banner') => {
  addEvent({
    action: EventAction.Click,
    category: EventCategory.Tutorial,
    screen: ScreenName.FavoriteSymbols,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        value: position,
        label: 'click_position',
      },
    ],
  });
};

export const isLevel5OrAbove = (badge: Badge) => {
  if (!badge) return false;
  const lv = (badge as string).replace('level', '');
  return lv && parseInt(lv) >= 5;
};

export const isLevel4OrAbove = (badge: Badge) => {
  if (!badge) return false;
  const lv = (badge as string).replace('level', '');
  return lv && parseInt(lv) >= 4;
};

export const addPageViewEvent = (path: string) => {
  addEvent({
    action: EventAction.PageView,
    category: EventCategory.WebAnalytics,
    screen: ScreenName.HomeTimeline,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        value: path,
        label: 'view_path',
      },
    ],
  });
};

export const isEnableAnimeFunction = (user: User) => {
  if (!user) return false;
  if (!user.feature_flags?.feature_flags) return false;
  const animeFunctionFlag = (user.feature_flags?.feature_flags || []).find(
    (flag) => flag.name === 'ai_anime_character'
  );
  return !!animeFunctionFlag?.active;
};

export const isEnableDiscountCampaign = (currentUser: User, creator: User) => {
  if (!creator) return false;
  if (!creator?.is_open_for_subscription || !creator.is_creator) return false;
  const creatorEnableCampaign = creator?.joined_promotions?.some(
    (promotion) => promotion.type === DISCOUNT_75_PRIME_SUB_EVENT
  );
  if (!currentUser) return creatorEnableCampaign;
  const discountPrimeSubEvent = currentUser?.applicable_promotions?.find(
    (event) => event.type === DISCOUNT_75_PRIME_SUB_EVENT
  );
  if (!discountPrimeSubEvent) return false;
  const currentUserAbleToJoinCampaign =
    discountPrimeSubEvent?.params?.current_subscription <
    discountPrimeSubEvent?.params?.max_subscription;
  return creatorEnableCampaign && currentUserAbleToJoinCampaign;
};

export const onboardingStepName = (step: number) => {
  switch (step) {
    case 0:
      return 'Onboarding View AI Alarm';
    case 1:
      return 'Onboarding Submit Invest Level';
    case 2:
      return 'Onboarding Submit Interested Topic';
    case 3:
      return 'Onboarding View Recommended Creators';
    case 4:
      return 'Onboarding View Paid Service Explanation ';
    case 5:
      return 'Onboarding View PostPrime Video Page';
    default:
      return '';
  }
};
